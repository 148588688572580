.sides-group {
  margin-bottom: 1rem;
}

.sides-group-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-transform: capitalize;
  border-bottom:1px solid silver;
  padding:0.3rem 0;
}
