tr:nth-child(even),
.topping-headings,
.addToCartList {
  background-color: #fff8ee;
}
.divider {
  border-color: #ff8c00 !important;
}
thead {
  background-color: #ff8c00;
  color: #fff;
}
h6 {
  font-size: 0.8rem;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #7a3ee7;
}
.nav-tabs .nav-link {
  color: #525252;
}
.topping-list {
  max-height: 17rem;
  overflow-y: auto;
  width: 100%;
}
.tab-content {
  min-width: 100%;
}
.addToCartList {
  height: calc(100vh - 120px);
  overflow-y: auto;
}
.inputGroupTxt {
  background-color: #f7f7f7;
}
.form-control,
.form-select,
.inputGroupTxt {
  font-size: 0.75rem;
}
.form-select,
.form-control {
  padding-top: 4px;
  padding-bottom: 4px;
}
.addToCartbtn,
.submitOrderbtn {
  background-color: #ff8c00;
  color: #ffffff;
}

/* Pizza Selection Tabs Menu */
.psTabs {
  background-color: white;
  border: none !important;
}
.psTabsUl {
  border-bottom: none;
  box-shadow: 1px 2px 4px 1px rgba(173, 173, 173, 0.2);
}
.psTabs:hover {
  border: none;
}
.psTabs.active {
  border: none;
  background-color: #ffcb8a !important;
  border-radius: 0%;
  color: #000000 !important;
  font-weight: 600;
}
.orderContainer {
  height: calc(100vh - 80px);
  overflow: hidden;
}
.height-100 {
  height: 100%;
}
.sectionTwo {
  overflow: auto;
  height: 95%;
}
.cart {
  height: calc(100vh - 128px);
  justify-content: space-between;
}
.sectionOne {
  height: calc(100vh - 128px);
  overflow-y: scroll;
}
.badge-poutine {
  text-transform: capitalize;
  color: #b1130be4;
}
.badge-side {
  text-transform: capitalize;
  color: #094f90e4;
}
.badge-plantbites {
  text-transform: capitalize;
  color: #1d9238e4;
}
.badge-tenders {
  text-transform: capitalize;
  color: #638d0ee4;
}
.badge-subs {
  text-transform: capitalize;
  color: #8b0b45e4;
}
.custom-hover:hover {
  background-color: #af886f;
  /* color: #ffffff; */
  /* border: none; */
}
