body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.75rem;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.profile-container {
  font-size: 1rem;
}

.profile-container label {
  font-size: 0.9rem;
}

.profile-container .form-control {
  font-size: 1rem;
}

.btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  align-content: center;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.psTabs img {
  transition: all 0.3s ease-in-out;
}

.psTabs.active img {
  scale: 1.1;
  transform: rotate(10deg);
}

.text-danger-dark {
  color:#d1150b;
}
.fs-600 {
  font-weight: 600;
}