.customize {
  background-color: #ff8c00;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
}
.customize:hover {
  background-color: #f05c06;
  color: #ffffff;
}
.customizablePizza {
  max-height: calc(100vh - 170px);
  overflow-y: scroll;
}
.cart-add {
  background-color: #817c7c;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  cursor: pointer;
}
.cart-add:hover {
  background-color: #f08706;
  color: #ffffff;
}
.text-medium {
  font-weight: 600;
}
