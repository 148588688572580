.box-border {
  height: 85vh;
  overflow-y: scroll;
  border-radius: 10px;
  background-color: #dbdbdb;
  padding: 1rem;
}

.box-border.ready {
  background-color: #e2f7de;
}

.hr-seprator {
  width: 100%;
  height: 5px;
}

.grey {
  background: #757575;
}

.green {
  background: #1fb401;
}

.status {
  font-size: 0.8rem;
}

.order-number {
  font-size: large;
  font-weight: 700;
}

.phone-number {
  font-weight: 600;
  font-size: 1rem;
  color: #747474;
}
