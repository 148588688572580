/* billing.css */
@media print {
  body {
    width: 100%; /* Set the paper width to 100% to fit any printer page */
    font-size: 0.9rem !important;
    /* margin: 0px; */
  } /* Add other styles as needed for your print layout */

  /* Developer: ShreyasM, Working Date: 27-11-2023 */
  /* Its used for font size of prices for all on print page only */
  .pro_price {
    font-size: 0.8rem !important;
  }
  .summary {
    font-size: 0.95rem I !important;
  }
}
