.header {
  z-index: 1000;
  box-shadow: 0px 2px 2px 1px rgba(76, 76, 76, 0.2);
}
.brandName {
  font-size: 1.1rem !important;
  color: #ff8c00;
font-weight: 700;
}
.profile-menu,
.notify-menu {
  z-index: 1001;
  width: auto;
  font-size: 0.75rem;
}
.imgDiv {
  width: 40px;
  height: 40px;
}
.profileDiv,
.notify-header {
  background-color: #ff9b21;
}
.profileDiv {
  padding-right: 2.8rem !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e2e2e2;
}
.nav-text {
  color: #525252;
}
.orderDropdown {
  font-size: 0.75rem !important;
}
.orderDrop:hover {
  background-color: #e2e2e2;
}
.dropdown-item {
  cursor: pointer;
}
.bell-button {
  border: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}
.fa-bell-o {
  font-size: 1.1rem;
}
.notify-header h6 {
  font-size: 0.95rem;
}
.notify-header p {
  font-size: 0.85rem;
  font-weight: 500;
}
.notify-count {
  position: absolute;
  top: 0;
  right: -2px;
  color: #ffffff;
  background-color: #ff0000;
  width: 12px;
  height: 12px;
  font-size: 0.6rem;
  border-radius: 50%;
}
.notify-menu .dropdown-item:hover,
.notify-menu .dropdown-item:visited,
.notify-menu .dropdown-item.active,
.notify-menu .header:focus {
  background-color: #ffffff !important;
  color: #1c1c1c;
}
.profile-button {
  border: none;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.reqnotifyBtn {
  font-size: 0.85rem;
  font-weight: 700;
  background-color: #ff8c00;
  border: none;
}







@keyframes blink {
  0% { background-color: #ff8c00; }
  50% { background-color: transparent; }
  100% { background-color: #ff8c00; }
}

.nav-link {
  position: relative; 
  overflow: hidden; 
}

.border-red::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ff8c00;
  animation: blink 1s infinite;
  z-index: -1; 
}

.text {
  color: black !important; 
}

/* .border-red {
  background-color:  red !important;

}
.color{
  color: white !important;
} */