.button-primary {
  border: none;
  padding: 0.35rem 0.75rem;
  color: #ffffff;
  background-color: #ff8c00;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.button-primary:hover {
  background-color: #f07502;
}

.profile-picture {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  object-fit: cover;
}

.text-large-pizza-price {
  color: #3d007e;
}

.text-xlarge-pizza-price {
  color: #00657e;
}

.form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.form-check-label {
  margin-top: 0.1rem;
  margin-left: 0.2rem;
}

.form-check-input:checked {
  background-color: #ff8c00;
  border-color: #ff8c00;
}

.store-highlight {
  color: #b22b00;
  font-weight: 600;
  background: #f5e6d4;
  padding: 0.15rem;
  border-radius: 5px;
  text-align: left;
}

.scroll-y {
  overflow-y: scroll;
}

.h-70vh {
  height: 70vh;
}

.cart-copy {
  font-size: 1.1rem;
  color: #078a42;
}

.cart-edit {
  font-size: 1.1rem;
  color: #142ec0;
}

.comment-font {
  font-size: 1.1rem!important;
}